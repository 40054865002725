import styled from "styled-components"

export default styled.section`
  background-color: transparent;

  .container {
    max-width: 60rem;
    margin: 6rem auto;
    p {
      margin-bottom: 10px;
    }

    * {
      color: var(--red);
    }
  }
`
