import React from "react"
import { Helmet } from "react-helmet"
import SubHeader from "../components/shared/subHeader"
import Description from "../components/contact-peter/description"
import ContactForm from "../components/contact-peter/contactForm"

const ContactPeter = () => {
  return (
    <div className="subpage-container">
      <Helmet>
        <title>Get in touch | Lateral Partners</title>
      </Helmet>
      <SubHeader title="Get in touch" />
      <Description />
      <ContactForm />
    </div>
  )
}

export default ContactPeter
