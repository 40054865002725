import React, { useEffect } from 'react'
import ContactFormStyle from "./contactFormStyle"

const ContactForm = () => {

  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://lateralpartners.activehosted.com/f/embed.php?id=11"
    script.async = true

    document.body.appendChild(script)
  }, [])

  return (
    <ContactFormStyle>
      <div className="container">
        <div className="_form_11"></div>
      </div>
    </ContactFormStyle>
  )
}

export default ContactForm
