export const assets = {
  bkLogo: require("@static/assets/bkLogo.svg"),
};

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1279;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;
export const TINY_BREAKPOINT = 380;

export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  maroon: "#551E2B",
  cream: "#F7F2EE",
  lightBlue: "#4AAFBC",
  red: "#EA3C31",
  green: "#6F7247",
  blue: "#1A478B",
  teal: "#2C7F76",
  purple: "#8A2D44",
  darkCream: "#f1ede7",
};

export const fontFamilies = {
  thin: "thin",
  regular: "regular",
  medium: "medium",
  bold: "bold",
};

export const fontWeights = {
  normal: 400,
  medium: 500,
  bold: 700,
};

export const PRIMARY_COLOR = colors.maroon;
export const SECONDARY_COLOR = colors.black;

export const fontSizes = {
  p: { default: 14, mobile: 14 },
  h1: { default: 50, mobile: 36 },
  h2: { default: 30, mobile: 25 },
  h3: { default: 20, mobile: 20 },
  h4: { default: 20, mobile: 20 },
  span: { default: 16, mobile: 12 },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  navy: (opacity?: number) => `rgba(0, 51, 82, ${opacity ?? "1"})`,
  lightBlue: (opacity?: number) => `rgba(224, 240, 255, ${opacity ?? "1"})`,
  maroon: (opacity?: number) => `rgba(85, 30, 43, ${opacity ?? "1"})`,
};

export const breakPoint = {
  mobile: MOBILE_BREAKPOINT,
  ipad: IPAD_BREAKPOINT,
  tablet: TABLET_BREAKPOINT,
  ipadPro: IPAD_PRO_BREAKPOINT,
  smallLaptop: SMALL_LAPTOP_BREAKPOINT,
  laptop: LAPTOP_BREAKPOINT,
  twenty: TWENTY_INCH_SCREEN,
  imac: IMAC_BREAKPOINT,
};

export const mediaQueryWidth = {
  imacDown: `(max-width: ${breakPoint.imac}px)`,
  laptopUp: `(min-width: ${breakPoint.laptop + 1}px)`,
  laptopDown: `(max-width: ${breakPoint.laptop}px)`,
  twentyUp: `(min-width: ${breakPoint.twenty + 1}px)`,
  twentyDown: `(max-width: ${breakPoint.twenty}px)`,
  smallLaptopUp: `(min-width: ${breakPoint.smallLaptop + 1}px)`,
  smallLaptopDown: `(max-width: ${breakPoint.smallLaptop}px)`,
  tabletUp: `(min-width: ${breakPoint.tablet + 1}px)`,
  tablet: `(min-width: ${breakPoint.tablet + 1}px) and (max-width: ${breakPoint.laptop}px)`,
  tabletDown: `(max-width: ${breakPoint.tablet}px)`,
  ipadProUp: `(min-width: ${breakPoint.ipadPro + 1}px)`,
  ipadProDown: `(max-width: ${breakPoint.ipadPro}px)`,
  ipadUp: `(min-width: ${breakPoint.ipad + 1}px)`,
  ipad: `(min-width: ${breakPoint.ipad + 1}px) and (max-width: ${breakPoint.tablet}px)`,
  ipadDown: `(max-width: ${breakPoint.ipad}px)`,
  mobileUp: `(min-width: ${breakPoint.mobile + 1}px)`,
  mobileDown: `(max-width: ${breakPoint.mobile}px)`,
  tinyUp: `(min-width: ${TINY_BREAKPOINT + 1}px)`,
  tinyDown: `(max-width: ${TINY_BREAKPOINT}px)`,
};

const m = `@media only screen and`;
export const mediaQuery = {
  tinyUp: `${m} ${mediaQueryWidth.tinyUp}`,
  tinyDown: `${m} ${mediaQueryWidth.tinyDown}`,
  mobileDown: `${m} ${mediaQueryWidth.mobileDown}`,
  mobileUp: `${m} ${mediaQueryWidth.mobileUp}`,
  ipadDown: `${m} ${mediaQueryWidth.ipadDown}`,
  ipad: `${m} ${mediaQueryWidth.ipad}`,
  ipadUp: `${m} ${mediaQueryWidth.ipadUp}`,
  tabletDown: `${m} ${mediaQueryWidth.tabletDown}`,
  tablet: `${m} ${mediaQueryWidth.tablet}`,
  tabletUp: `${m} ${mediaQueryWidth.tabletUp}`,
  smallLaptopDown: `${m} ${mediaQueryWidth.smallLaptopDown}`,
  smallLaptopUp: `${m} ${mediaQueryWidth.smallLaptopUp}`,
  laptopDown: `${m} ${mediaQueryWidth.laptopDown}`,
  laptopUp: `${m} ${mediaQueryWidth.laptopUp}`,
  ipadProUp: `${m} ${mediaQueryWidth.ipadProUp}`,
  ipadProDown: `${m} ${mediaQueryWidth.ipadProDown}`,
  twentyUp: `${m} ${mediaQueryWidth.twentyUp}`,
  twentyDown: `${m} ${mediaQueryWidth.twentyDown}`,
  imacDown: `${m} ${mediaQueryWidth.imacDown}`,
};

export const zIndex = {
  dropdown: 10,
  header: 15,
  dialog: 20,
  tooltip: 10,
};

export const headerHeight = {
  default: "105px",
  defaultInt: 105,
  mobile: "68px",
  mobileInt: 68,
};
