import React from "react"
import DescriptionStyle from "./descriptionStyle"

const Description = () => {
  return (
    <DescriptionStyle>
      <div className="container">
        <h2>We’re here to help.</h2>
        <p>
          Need more information on how Lateral Partners could help with your
          home loans process? Book a time to chat with Peter Norris below.
        </p>
        <p>
          With over 12 years experience in the industry, Peter specialises in
          creating tailored solutions for residential clients, and has extensive
          networks and connections across the industry. Tell us a little more
          about your situation below and we’ll be in touch.
        </p>
      </div>
    </DescriptionStyle>
  )
}

export default Description
