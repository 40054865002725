import styled from "styled-components"

export default styled.section`
  background-color: transparent;
  margin-bottom: 10rem;

  .container {
    max-width: 60rem;

    form {
      padding: 0 !important;
      width: 100% !important;

      button._submit {
        margin-top: 2rem;
      }
    }
  }
`